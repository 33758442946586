import * as React from "react";
import { Button } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { TranslatedString } from "../../../pods/i18n/translate";
import { translate } from "../../../utils/translate";
import { UnstyledButton } from "../../buttons";

export type MultiStageProps = {
  children: React.ReactNode;
  nextText?: TranslatedString;
  backText?: TranslatedString;
  doneText?: TranslatedString;
  onNext?: (stageId: number) => void;
  onPrev?: (stageId: number) => void;
  close: () => void;
};

function MultiStage(props: MultiStageProps) {
  const [stageIdx, setStageIdx] = React.useState(0);

  const items = React.Children.toArray(props.children);

  // Next
  const nextText = props.nextText || translate("dojo.common:actions.next", "Next");
  const doneText = props.doneText || translate("dojo.common:actions.done_string", "Done");
  const nextExists = stageIdx < React.Children.count(props.children) - 1;

  // Back
  const backText = props.backText || translate("dojo.common:actions.back", "Back");

  const changeStage = (amt: number): void => {
    if (amt > 0) {
      // we're moving forward
      !!props.onNext && props.onNext(stageIdx);
    }

    if (amt < 0) {
      //we're moving backwards
      !!props.onPrev && props.onPrev(stageIdx);
    }

    let newIdx = stageIdx + amt;
    if (newIdx >= React.Children.count(props.children)) newIdx = -1;
    setStageIdx(newIdx);
  };

  return (
    <div>
      {items[stageIdx]}
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "dt_m",
          position: "relative",
          justifyContent: "flex-end",
        }}
      >
        {stageIdx > 0 ? (
          <Button kind="tertiary" size="s" onClick={() => changeStage(-1)} data-name="multi_stage:back">
            {backText}
          </Button>
        ) : null}
        <div sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          {items.map((_, idx) => (
            <UnstyledButton
              key={idx}
              sx={dotStyles}
              onClick={() => setStageIdx(idx)}
              aria-pressed={idx === stageIdx}
              data-name="dot"
              aria-label={`Go to stage ${idx + 1}`}
            />
          ))}
        </div>
        {nextExists ? (
          <Button onClick={() => changeStage(1)} size="s" sx={{ marginLeft: "auto" }} data-name={"multi_stage:next"}>
            {nextText}
          </Button>
        ) : (
          <Button onClick={props.close} size="s" sx={{ marginLeft: "auto" }} data-name={"multi_stage:done"}>
            {doneText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default MultiStage;

const dotStyles: ThemeUIStyleObject = {
  display: "inline-block",
  marginX: "dt_s",
  width: "0",
  borderWidth: "0.5rem",
  borderRadius: "dt_radius_round",
  borderStyle: "solid",
  borderColor: "dt_border_functional",
  '&[aria-pressed="true"]': {
    cursor: "default",
    borderColor: "dt_border_active",
  },
};
