import isMobile from "ismobilejs";

/**
 * Resolve the name and url to the right store depending on the device type
 * (basically diserning between iphone ipad and android).
 *
 * - returns: {url, name}
 *   - url: Simply points to the app store depending on the device.
 *   - name: One of the keys (iphone | ipad | android)
 */
export default function resolveMobileAppStore(): { url: string; name: string | null } {
  let url;
  let name;

  if (isMobile.apple.tablet) {
    url = "https://itunes.apple.com/us/app/classdojo/id552602056?mt=8";
    name = "ipad";
  } else if (isMobile.apple.device) {
    // If not an apple tablet then any other apple device show the iphone store.
    url = "https://itunes.apple.com/us/app/classdojo/id552602056?mt=8";
    name = "iphone";
  } else if (isMobile.android.device) {
    url = "https://play.google.com/store/apps/details?id=com.classdojo.android";
    name = "android";
  } else {
    return { url: "https://www.classdojo.com/download/?type=parent", name: null };
  }

  return { url, name };
}
